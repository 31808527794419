<template>
<v-container fluid>
  <v-row justify="center" dense>
    <v-col v-for="(item, index) of $store.state.streams" :key="index" md="4">
      <v-card max-width="250" align="center" >
      <v-card-title class="text-subtitle-1 text-truncate">{{item.clientId}}</v-card-title>
<v-card-text>

      <video
      class="mx-auto"
        width="200"
        height="200"
        :ref="item.clientId"
        autoplay
        muted
        :src-object.prop.camel="item.stream"
      ></video>
</v-card-text>

      </v-card>
    </v-col>
  </v-row>

</v-container>
</template>

<script>

export default {
  name: "Cams",
  data: () => ({
    stopPing: false,
    myStream: null,
    clients: [],
    peer: null,
    getUserMedia:
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia,
  }),
};
</script>

<style lang="scss" scoped>
</style>